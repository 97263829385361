import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDJX4cZakc9trFgLCzGerF0O7NshPLRGyI',
  authDomain: 'naira-f9c0b.firebaseapp.com',
  projectId: 'naira-f9c0b',
  storageBucket: 'naira-f9c0b.appspot.com',
  messagingSenderId: '209452583260',
  appId: '1:209452583260:web:765bb12dc69cb0d06b9999'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
