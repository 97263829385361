import React from 'react';
import nairaLogo from '../assets/img/naira-logo128x128.png';
import audios from '../assets/audio/audios';
import { FiVolume, FiVolume1 } from 'react-icons/fi';
import AudioPlayer from '../components/AudioPlayer';

const Title = () => {
  const ai = Math.floor(Math.random() * audios.length);
  const audio = audios[ai];
  console.log(ai);
  return (
    <div className="title">
      <div className="logo-box">
        <div className="logo">
          <h1>Նաիրա</h1>
          <img src={nairaLogo} alt="naira" width={128} />
        </div>
        <AudioPlayer
          url={audio}
          startPlayIcon={<FiVolume style={{ fontSize: 30 }} />}
          stopPlayIcon={<FiVolume1 style={{ fontSize: 30 }} />}
        />
      </div>
      <h2>Ծաղիկների աշխարհում</h2>
      <p>Վեբսայթը Նվիրվում է սիրելի ուսանողական ընկերուհուս</p>
    </div>
  );
};

export default Title;
