import React, { useState, useEffect } from 'react';

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const AudioPlayer = ({ url, startPlayIcon, stopPlayIcon }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div
      aria-label="naira"
      onClick={toggle}
      style={{ color: playing ? 'rgb(233, 30, 99)' : null }}>
      {playing ? stopPlayIcon : startPlayIcon}
    </div>
  );
};

export default AudioPlayer;
