import audio_0 from './marieta-badalyan/Ծիծեռնակ-Մարիետա-Բադալյան.mp3';
import audio_1 from './marieta-badalyan/Անձրև.mp3';
import audio_2 from './marieta-badalyan/Գարուն-Մարիետա-Բադալյան.mp3';
import audio_3 from './marieta-badalyan/Գետակի-վրա-Մարիետա-Բադալյան.mp3';
import audio_4 from './marieta-badalyan/Դեղնած-դաշտերին-Մարիետա-Բադալյան.mp3';
import audio_5 from './marieta-badalyan/Ես-բլբուլ-եմ-Մարիետա-Բադալյան.mp3';
import audio_6 from './marieta-badalyan/Մարիետա-Բադալյան-Ինձ-նման-թախծոտ.mp3';
import audio_7 from './marieta-badalyan/Մարիետա-Բադալյան-Ռոմանոս-Մելիքյան-Վարդը.mp3';
import audio_8 from './marieta-badalyan/Մարիետա-Բադալյան-Ուռի.mp3';
import audio_9 from './marieta-badalyan/Մարիետա-Բադալյան-Կոմիտաս-Լորիկ.mp3';
import audio_10 from './marieta-badalyan/Մարիետա-Բադալյան-Կոմիտաս-Կաքավիկ.mp3';
import audio_11 from './marieta-badalyan/Մարիետա-Բադալյան-Հովհաննես-Թումանյան-Ախ-ինչ-լավ-է.mp3';

export default [
  audio_0,
  audio_1,
  audio_2,
  audio_3,
  audio_4,
  audio_5,
  audio_6,
  audio_7,
  audio_8,
  audio_9,
  audio_10,
  audio_11
];
